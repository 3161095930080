import * as React from "react";
import {Link} from "gatsby";
import Button from "../components/Button";

const NotFoundPage = () => {
  return (
      <main
          className={"h-screen white-bg flex justify-center items-center flex-col"}
      >
        <title>Robert Moore | 404</title>
        <h1
            className={
              "2xl:mb-6  mb-4 text-6 mt-2 text-center sm:text-12 tiny:text-7 xs:text-9 xs:mb-4 sm:text-11 md:text-13 lg:text-14 2xl:text-11 3xl:text-14 text-blue-400 font-bold"
            }
        >

          Page not found
          <br/>
          <br/>
          <span style={{color: '#676767'}}>404</span>
        </h1>
        <p
            className={
              "text-2 text-center w-4/5 2x:w-3/4 mx-auto 2xl:text-3 mt-4 tiny:mt-6 md:mt-8 lg:mt-16"
            }
        >
          Sorry we couldn’t find what you were looking for.
          <br/>
          <Link to={"/"}>
            <Button title={"HOME"}/>
          </Link>
          .
        </p>
      </main>
  );
};

export default NotFoundPage;
